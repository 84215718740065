import { Box, Grid, styled, styledComponent } from '@hermes/web-components'

export const HoursContainer = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{
  isActive: boolean
}>(({ isActive }) => ({
  width: '50%',
  borderTopColor: '#d5def9',
  '@media (max-width: 1055px)': {
    display: isActive ? 'flex' : 'none',
    borderTopWidth: '1px',
    width: '100%',
    borderTopColor: '#d5def9'
  }
}))

export const DetailsGrid = styled(Grid)`
  border-color: red;
  border-right-width: 1px;
  width: 50%;
  @media (max-width: 1055px) {
    width: 100%;
  }
`

export const Divider = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{
  isActive: boolean
}>(({ isActive }) => ({
  backgroundColor: '#d5def9',
  width: '1px',
  '@media (max-width: 1055px)': {
    display: isActive ? 'block' : 'none',
    width: '100%',
    height: '1px',
    marginTop: '1.5rem',
    marginBottom: '2rem'
  }
}))

export const ExpandIconContainer = styled(Box)`
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  bottom: 4px;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 4px;
  width: 24px;
`
