import { useMemo, useContext } from 'react'
import { AppDataContext } from '../providers/AppData'
import getSpecialistSchema from '../utils/schema/specialist'
import { SpecialistProfile } from '../types/specialist'
import useTranslateMessage from './useTranslateMessage'
import { useVisibilityComponents } from './useVisibilityComponents'

export default function useSpecialistSchema(specialist: SpecialistProfile) {
  const { locale, language, isRtl } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const visibilityComponents = useVisibilityComponents({ specialist })

  return useMemo(
    () => getSpecialistSchema(specialist, language, locale, translate, visibilityComponents, isRtl),
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specialist]
  )
}
