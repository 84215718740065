import React, { useContext } from 'react'
import { Grid, Button, Box, styledComponent, useMediaQuery, useTheme, Lazy, Typography } from '@hermes/web-components'
import RecommendationCard from '../../RecommendationCard'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { SearchPeerRecommendationsContext } from '../../../providers/SearchPeerRecommendations'
import { Statistic } from '../../../types/statistics'
import dynamic from 'next/dynamic'

const SpecialistRecommendationsOverview = dynamic(() => import('./SpecialistRecommendationsOverview'), { ssr: false })

type SpecialistRecommendationsProps = {
  statistics: Statistic[]
}

const ColumnsRoot = styledComponent(Box)(({ theme }) => ({
  columnCount: 2,
  columnWidth: '50%',
  columnGap: theme.spacing(2),
  breakInside: 'avoid',
  [theme.breakpoints.down('sm')]: {
    columnCount: 1,
    columnGap: 0,
    columnWidth: '100%'
  }
}))

function SpecialistRecommendations({ statistics }: SpecialistRecommendationsProps) {
  const theme = useTheme()
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const translate = useTranslateMessage()
  const {
    state: {
      recommendations = [],
      totalCount,
      filters: { search, keyword },
      initiallyEmpty
    },
    loadMore
  } = useContext(SearchPeerRecommendationsContext)

  if (initiallyEmpty) {
    return null
  }

  const onLoadMore = () => {
    loadMore()
    window.dataLayer.push({ event: 'prf_pg_endorsements_load_more' })
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h2" component="h2">
          {translate('endorsements')}
        </Typography>
        <Grid sx={{ display: 'inline-flex' }} alignItems={{ sm: 'center', xs: 'end' }}>
          <Typography variant="body2" color="primary.main" sx={{ mr: 0.5 }}>
            {translate('profile.how_worked_with_specialist')}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          contentVisibility: 'auto',
          containIntrinsicHeight: '300px'
        }}
      >
        <Lazy>
          <SpecialistRecommendationsOverview statistics={statistics} total={totalCount} />
        </Lazy>
      </Box>
      <ColumnsRoot>
        {recommendations.map((recommendation) => (
          <Box key={`specialist-recommendation-${recommendation.id}`} sx={{ mb: 2, breakInside: 'avoid' }}>
            <RecommendationCard
              selectedKeyword={keyword}
              {...recommendation}
              highlightedText={search}
              statistics={statistics}
            />
          </Box>
        ))}
      </ColumnsRoot>
      {recommendations.length < totalCount && (
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Button variant="outlined" onClick={onLoadMore} fullWidth={!!onlySmallScreen}>
            {translate('peerRecommendations.load_more')}
          </Button>
        </Grid>
      )}
    </>
  )
}

export default SpecialistRecommendations
