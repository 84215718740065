import React from 'react'

const Expand = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" transform="translate(0.184326 0.202271)" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1809 2.85565L14.1809 2.20565L13.5309 2.20565L9.68614 2.20565L9.68614 3.50565L11.9617 3.50565L9.00692 6.46041L9.92616 7.37965L12.8809 4.42489L12.8809 6.70044L14.1809 6.70044L14.1809 2.85565Z"
      fill="#2B59E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5309 14.1989L14.1809 14.1989L14.1809 13.5489L14.1809 9.70408L12.8809 9.70408L12.8809 11.9796L9.92618 9.02487L9.00694 9.94411L11.9617 12.8989L9.68616 12.8989L9.68616 14.1989L13.5309 14.1989Z"
      fill="#2B59E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.18772 13.5489L2.18772 14.1989L2.83772 14.1989L6.68252 14.1989L6.68252 12.8989L4.40696 12.8989L7.36173 9.94413L6.44249 9.02489L3.48772 11.9797L3.48772 9.7041L2.18772 9.7041L2.18772 13.5489Z"
      fill="#2B59E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8377 2.20567L2.1877 2.20567L2.1877 2.85567L2.1877 6.70046L3.4877 6.70046L3.4877 4.42491L6.44247 7.37967L7.36171 6.46044L4.40694 3.50567L6.6825 3.50567L6.6825 2.20567L2.8377 2.20567Z"
      fill="#2B59E0"
    />
  </svg>
)

export default Expand
