import { createContext, useState, useEffect, PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { getSpecialistPeerRecommendations } from '../../api/specialist'

import {
  SearchPeerRecommendationsState,
  SearchPeerRecommendationsProps,
  SearchPeerRecommendationsFilters
} from './types'

const SearchPeerRecommendationsContext = createContext<SearchPeerRecommendationsState>({
  state: {
    recommendations: [],
    filters: {
      page: 1,
      sort: 'desc',
      search: '',
      keyword: ''
    },
    initiallyEmpty: false,
    isLoading: false,
    totalCount: 0
  },
  changeSort: (sort) => sort,
  changeSearch: (search) => search,
  changeKeyword: (keyword) => keyword,
  loadMore: () => ({})
})

function SearchPeerRecommendationsContextProvider({
  recommendations: initiallRecommendations,
  profileId,
  totalCount: initialTotalCount,
  defaultFilters,
  children
}: PropsWithChildren<SearchPeerRecommendationsProps>) {
  const router = useRouter()

  const [state, setState] = useState<SearchPeerRecommendationsState['state']>({
    recommendations: initiallRecommendations || [],
    totalCount: initialTotalCount || 0,
    filters: {
      page: 1,
      sort: 'desc',
      search: '',
      keyword: '',
      ...defaultFilters
    },
    initiallyEmpty: !initialTotalCount,
    isLoading: false
  })

  useEffect(() => {
    if (initiallRecommendations) {
      setState((prevState) => ({
        ...prevState,
        recommendations: initiallRecommendations
      }))
    }
  }, [initiallRecommendations])

  useEffect(() => {
    const loadPeerRecommendationsFromApi = async () => {
      const { result = [], totalCount = 0 } = await getSpecialistPeerRecommendations({
        id: profileId,
        lang: router.locale as string,
        sort: state.filters.sort,
        search: state.filters.search,
        keyword: state.filters.keyword,
        offset: (state.filters.page - 1) * 4,
        limit: 4
      })

      setState((prevState) => ({
        ...prevState,
        recommendations: state.filters.page > 1 ? [...prevState.recommendations, ...result] : result,
        isLoading: false,
        totalCount
      }))
    }

    if (state.isLoading) {
      loadPeerRecommendationsFromApi()
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoading])

  const changeSort = (sort: string) =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: { ...prev.filters, sort: sort as SearchPeerRecommendationsFilters['sort'], page: 1 }
    }))
  const changeSearch = (search: string) =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: { ...prev.filters, search, page: 1 }
    }))
  const changeKeyword = (keyword: string) =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: { ...prev.filters, keyword, page: 1 }
    }))

  const loadMore = () =>
    setState((prev) => ({
      ...prev,
      isLoading: true,
      filters: {
        ...prev.filters,
        page: prev.filters.page + 1
      }
    }))

  return (
    <SearchPeerRecommendationsContext.Provider
      value={{
        state,
        changeKeyword,
        changeSort,
        changeSearch,
        loadMore
      }}
    >
      {children}
    </SearchPeerRecommendationsContext.Provider>
  )
}

export { SearchPeerRecommendationsContextProvider, SearchPeerRecommendationsContext }
